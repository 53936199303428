.pb-undefault {
  /* background-color: grey; */
  border-width: 0px;
  width: 100%;
  background-color: transparent;
  outline: none;
}

.pb-dropdown {
  padding: 8px;
  font-size: 16px;
  border:solid 1px #ccc;
  border-radius: 3px;
}
