.pb-bottom-sheet {
  position: absolute;
  bottom: 0;
  width: 100vw;
  min-height: var(--dynamic-height, 350px);
  /* background-color: #0000001a; */
  background-color: #00000000;

  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}
.pb-bottom-sheet-content {
  flex-basis: 80%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.pb-close-btn {
  position: absolute;
  top: -20px;
  border-radius: 100%;
  padding: 7px 14px;
  background-color: white;
  color: black;
  box-shadow: var(--elevate-float);
  font-weight: bold;
  cursor: pointer;
}

.pb-close-btn:hover {
  opacity: 0.8;
  transition: 0.4 ease-in-out;
}

.pb-bs-ribbon {
  position: absolute;
  top: -20px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 7px 20px;
  background-color: white;
  color: #383838;
  box-shadow: var(--elevate-float);
  font-weight: bold;

  /* min-width: 100px; */
}

.pb-bs-ribbon * {
  font-size: 18px;
}
