.pb-sectionizer {
  width: 99%;
  background: transparent;
  min-height: 250px;
  border: dashed 1px #0b9edc;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pb-sectionizer:hover {
  border: solid 2px #0b9edc;
}

.pb-sectionizer-in-focus {
  border: solid 2px red;
}

.pb-section-r {
  border-radius: 100%;
  padding: 0px 10px;
  font-weight: bold;
  color: white;
  font-size: 23px;
  background-color: #0b9edc;
  cursor: pointer;
}

.pb-sectionizer small {
  font-size: 12px;
  color: #0b9edc;
}

.pb-add-section {
  padding: 8px 25px;
  font-weight: bold;
  font-size: 14px;
  border: dashed 1px #0b9edc;
  background: #9fddeb47;
  color: #0b9edc;
  margin: 10px 0px;
  border-radius: 55px;
}

.pb-add-area {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pb-sectionizer-plus-icon {
  font-size: 21px;
  margin-bottom: 5px;
  color: white;
  /* border: 2px solid grey; */
  padding: 8px 10px;
  border-radius: 100%;
  background: #0b9edc;
}
